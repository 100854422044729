/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

/* Roboto|Berkshire+Swash|Quintessential */
@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Gentium+Book+Basic&family=Quintessential&family=Roboto&display=swap");
body {
  font-family: Roboto;
  color: #e6e6e6;
  overflow: hidden;
  margin: 0;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.05);
}

::-webkit-scrollbar {
  width: 7px;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.05);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.15);
}

iframe {
  border: none;
}

.mat-datepicker-content {
  border-radius: 8px;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   color: #e3af4c;
// }

mat-calendar {
  background-image: url("./assets/leather_light_background.png");
  border-radius: 8px;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.full {
  width: 100%;
  height: 100%;
}

.gold-gradient {
  background-size: 1em 1em;
  background: #fceabb;
  background: -moz-linear-gradient(
    top,
    #fceabb 0%,
    #fccd4d 50%,
    #f8b500 51%,
    #fbdf93 100%
  );
  background: -webkit-linear-gradient(
    top,
    #fceabb 0%,
    #fccd4d 50%,
    #f8b500 51%,
    #fbdf93 100%
  );
  background: linear-gradient(
    to bottom,
    #fceabb 0%,
    #fccd4d 50%,
    #f8b500 51%,
    #fbdf93 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fceabb', endColorstr='#fbdf93',GradientType=0 );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
}

.gold-gradient.with-hover:hover {
  background-size: 1em 1em;
  background: #ffffff;
  background: -moz-linear-gradient(
    top,
    #ffffff 0%,
    #f1f1f1 50%,
    #e1e1e1 51%,
    #f6f6f6 100%
  );
  background: -webkit-linear-gradient(
    top,
    #ffffff 0%,
    #f1f1f1 50%,
    #e1e1e1 51%,
    #f6f6f6 100%
  );
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #f1f1f1 50%,
    #e1e1e1 51%,
    #f6f6f6 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
}

.white-gradient {
  background-size: 1em 1em;
  background: #ffffff;
  background: -moz-linear-gradient(
    top,
    #ffffff 0%,
    #f1f1f1 50%,
    #e1e1e1 51%,
    #f6f6f6 100%
  );
  background: -webkit-linear-gradient(
    top,
    #ffffff 0%,
    #f1f1f1 50%,
    #e1e1e1 51%,
    #f6f6f6 100%
  );
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #f1f1f1 50%,
    #e1e1e1 51%,
    #f6f6f6 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
}

/* Perfect Scrollbar Overrides */
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  background-color: rgba(0, 0, 0, 0.45);
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  background-color: rgba(0, 0, 0, 0.35) !important;
  border-radius: 3px;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
  background-color: rgba(0, 0, 0, 0.25) !important;
  border-radius: 3px;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: rgba(0, 0, 0, 0.45);
}

.ps__thumb-y {
  background-color: rgba(0, 0, 0, 0.45);
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
  background-color: rgba(0, 0, 0, 0.35);
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
  background-color: rgba(0, 0, 0, 0.35);
}

.crux-dialog .mat-dialog-container {
  background-color: transparent;
  padding: 0;
  box-shadow: none;
}

.tile-container.full perfect-scrollbar .ps .ps-content {
  height: calc(100% - 36px);
}

.firebaseui-container {
  background-color: transparent !important;
  box-shadow: none !important;
  height: 100%;
}

.firebaseui-card-header {
  display: none !important;
}

.firebaseui-subtitle,
.firebaseui-title,
.firebaseui-text,
.firebaseui-input,
.firebaseui-input-invalid,
.firebaseui-label {
  color: #e6e6e6 !important;
}

.firebaseui-textfield .firebaseui-label::after {
  background-color: transparent !important;
}

.firebaseui-label {
  font-size: 12px !important;
  top: 4px !important;
}

.firebaseui-textfield .firebaseui-input,
.firebaseui-textfield .firebaseui-input-invalid {
  border: 10px solid #ffffff !important;
  background-color: transparent !important;
  outline: 0 !important;
  box-sizing: border-box !important;
  margin-left: -10px !important;
  margin-right: -10px !important;
  width: calc(100% + 20px) !important;

  border-image: url("./assets/images/text_field_normal.png") 10 repeat !important;
}

.firebaseui-textfield {
  padding-bottom: 0 !important;
}

// .firebaseui-button {
//   color: #969696 !important;
//   background-color: transparent !important;
//   margin: 0 !important;
// }

// .firebaseui-button::after {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0;

//   // background-image: url('./assets/images/button_orange.png') !important;
//   // background-size: contain !important;
//   // background-position: center center !important;
//   // background-repeat: no-repeat !important;
//   height: 100%;
//   width: 100%;

//   border: 1px solid #ffffff;
//   border-width: 18px 20px 16px 20px;
//   border-image: url('/assets/images/button_orange.png') 18 20 16 20 fill;
//   transform: translate3d(0, 0, 0);
//   box-sizing: border-box;
// }

// .firebaseui-button:hover::after {
//   color: #e6e6e6;
//   border-image-source: url('./assets/images/button_orange_hover.png');
// }

.firebaseui-form-actions {
  display: flex !important;
  justify-content: space-around !important;
}

.firebaseui-text-input-error {
  margin: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
}

.flex-fill {
  flex: 1;
}

// .ql-editor {
//   height: calc(100% - 64px);

//   &::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     border-radius: 0;
//     background-color: rgba(0, 0, 0, 0.05);
//   }

//   &::-webkit-scrollbar {
//     width: 7px;
//     border-radius: 0;
//     background-color: rgba(0, 0, 0, 0.05);
//   }

//   &::-webkit-scrollbar-thumb {
//     border-radius: 5px;
//     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     background-color: rgba(0, 0, 0, 0.15);
//   }
// }
